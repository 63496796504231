$primary: #3c1f00;
$secondary: rgba(187, 166, 154, 0.85);
$info: #252525;
$warning: #ffaa00;

.header-logo {
  height: 40px;
  display: block;
  width: auto;
}

@import "~bootstrap/scss/bootstrap";
html,body {
  height: 100%;
}
 
.mt-25 {
  margin-top: 25px !important;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

.tooltip-inner {
  background-color: $primary;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before{
	border-bottom-color: $primary;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before{
	border-top-color:$primary;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before, .bs-tooltip-left .arrow::before{
	border-left-color: $primary;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right .arrow::before{
	border-right-color: $primary;
}

.form-control {
  border: none;
  border-bottom: 2px solid #ced4da;
  border-radius: 0px;
  background: transparent;
  padding-left: 5px;
  box-shadow: none;
}

.form-control:focus {
  border-bottom-color: $primary;
  color: #000;
  box-shadow: none;
}

.card {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.event-card .rotate,
.card-body .rotate {
  z-index: 8;
  float: right;
}

.event-card .rotate,
.card-body .rotate svg {
  color: rgba(20, 20, 20, 0.15);
  left: 0;
  left: auto;
  right: -10px;
  display: block;
  -webkit-transform: rotate(-44deg);
  -moz-transform: rotate(-44deg);
  -o-transform: rotate(-44deg);
  -ms-transform: rotate(-44deg);
  transform: rotate(-44deg);
}

.hover:hover,
.hover:focus{
  transform: scale(1.02);
  .overlay-button{
    visibility: visible;
  }
}

.overlay-button{
  position: absolute;
  visibility: hidden;
}

.user-background {
  max-height: 200px;
  min-height: 200px;
}

.event-card {
  position: relative;
  display: flex;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin: 0 auto;
  float: none;
  margin-bottom: 20px;
  margin-top: 20px;
}

.event-card .details{
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  display: flex;
  flex-wrap: wrap;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.kanban-column {
  min-width: 320px;
  margin-right: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.kanban-card {
  background-color: #f9f7f7;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 0px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.kanban-card:hover {
  cursor: pointer;
}

.member-overlay-selected {
  background-color: #fff;
  padding: 5px;
}

.member-overlay-selected:hover {
  background-color: #f9f7f7;
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #002d73;
  border-color: #002d73;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: '▶';
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

.modal-90w {
  width: 90%;
  max-width: none!important;
}

@media (min-width: 1000px){
  .user-background {
    max-height: 300px;
    min-height: 300px;
  }
}

@media (min-width: 1500px){
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1450px;
  }
  .user-background {
    max-height: 400px;
    min-height: 400px;
  }
}

@media (min-width: 2000px){
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1950px;
  }
  .user-background {
    max-height: 400px;
    min-height: 400px;
  }
}

@media (min-width: 2500px){
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 2450px;
  }
  .user-background {
    max-height: 400px;
    min-height: 400px;
  }
}