.laoderSpinner {
  z-index: 10000;
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.loaderCover {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(255,255,255,0.7);
  z-index:9999;
}